import React from 'react';
import Layout from '../components/Layout';
import Analysis from '../sections/Analysis';

const AnalysisPage = () => (
  <Layout logoHeader={true} mainHeader={false}>
    <Analysis />
  </Layout>
);

export default AnalysisPage;
