import React, { useEffect, useState, Fragment } from 'react';
import { Flex, Box, Image } from 'rebass/styled-components';
import { useStaticQuery, graphql, navigate } from 'gatsby';
import AnalysisResults from '../sections/AnalysisResults';
import StayInformedSection from '../components/StayInformedSection';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';
import { STORAGE_KEYS } from '../resources/constants';

import circuit from '../../assets/images/circuit.svg';
import { getReportService } from '../services/services';
import SecondarySection from '../components/SecondarySection';
import styled from 'styled-components';

const StyledBox = styled(Box)`
  @keyframes borderColorChange {
    0% {
      border-color: rgba(10, 153, 199, 0);
    }
    50% {
      border-color: rgba(10, 153, 199, 1);
    }
    100% {
      border-color: rgba(10, 153, 199, 0);
    }
  }
`;

const Analysis = () => {
  const [results, setResults] = useState(null);
  const [quizIdExists, setQuizIdExists] = useState(false);

  const getReport = () => {
    getReportService()
      .then(response => {
        if (response.status == '200') {
          setResults(response.data);
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  const data_section = useStaticQuery(
    graphql`
      query MyQueryAnalysis {
        contentfulAnalysisDev {
          section {
            ... on ContentfulSecondarySection {
              contentful_id
              internal {
                type
              }
              title
              text {
                text
              }
              #              icon {
              #                contentful_id
              #                file {
              #                  fileName
              #                  url
              #                }
              #              }
              #              button {
              #                contentful_id
              #                text
              #                url
              #                urlInternal
              #              }
            }
            ... on ContentfulFormSection {
              contentful_id
              internal {
                type
              }
              title
              button {
                contentful_id
                text
                url
                urlInternal
              }
              messageTitle
              messageDescription
              messageIcon {
                file {
                  url
                  fileName
                }
              }
              message
            }
          }
        }
      }
    `
  );

  useEffect(() => {
    if (!localStorage.getItem(STORAGE_KEYS.QUIZ_ID)) navigate('/');
    else {
      setQuizIdExists(true);
      getReport();
    }
  }, []);

  const goTo = () => {
    navigate('/analysis');
  };

  /* Back button browser */
  useEffect(() => {
    window.history.pushState({ page: 1 }, '', '');
    window.onpopstate = event => event && goTo();
  }, []);

  const handleTrackCustomEvent = actionName => {
    trackCustomEvent({
      category: 'analysis',
      action: actionName
    });
  };

  if (!quizIdExists) return null;
  return (
    <Fragment>
      <Flex
        flexDirection="column"
        maxWidth="550px"
        justifyContent="center"
        alignContent="center"
        pb={5}
      >
        <AnalysisResults
          buttonReport={true}
          dc={data_section}
          results={results}
          hd={handleTrackCustomEvent}
        />
        {data_section &&
          data_section.contentfulAnalysisDev &&
          data_section.contentfulAnalysisDev.section.map(section => (
            <Fragment key={section.contentful_id}>
              {section.internal.type === 'ContentfulSecondarySection' && (
                <StyledBox
                  mt={6}
                  style={{
                    border: '3px solid #0A99C7',
                    animation: 'borderColorChange 3s infinite'
                  }}
                >
                  <SecondarySection
                    data_section={section}
                    handleTrackCustomEvent={handleTrackCustomEvent}
                  />
                </StyledBox>
              )}
            </Fragment>
          ))}
        {data_section.contentfulAnalysisDev.section.map(section => (
          <Fragment key={section.contentful_id}>
            {section.internal.type === 'ContentfulFormSection' && (
              <Box mt={6} px={5}>
                <StayInformedSection
                  data_section={section}
                  handleTrackCustomEvent={handleTrackCustomEvent}
                ></StayInformedSection>
              </Box>
            )}
          </Fragment>
        ))}
      </Flex>
      <Flex flexDirection="column" width="100%">
        <Flex justifyContent="flex-start" mt={9}>
          <Image src={circuit} height="64px" alt="circuit"></Image>
        </Flex>
      </Flex>
    </Fragment>
  );
};

export default Analysis;
